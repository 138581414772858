import React, { Fragment, memo } from 'react';
import cl from 'classnames';

import { InvoiceCardItem } from './InvoiceCard.types';
import { InvoiceStatuses, invoiceStatusTexts } from '../../../invoicesTypes';
import { AvBillingInfoPaymentMethods } from '../../../../avBillingInfos/avBillingInfosTypes';

import { TeamLink } from '../../../../teams/helpers/TeamLink';
import { Translate } from '../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../helpers/MoneyHelper';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';

import { invoicesKeys, words } from '../../../../../locales/keys';

interface InvoiceCardProps {
  invoice: InvoiceCardItem;
}

function InvoiceCard({ invoice }: InvoiceCardProps) {
  return (
    <Fragment>
      <div className="flex justify-center mt-3 mb-6">
        <div
          className={cl(
            'text-center py-3 px-6 rounded-lg bg-opacity-20 w-72 max-w-full',
            invoice.status === InvoiceStatuses.CANCELED
              ? 'bg-gray-400'
              : 'bg-blue-500'
          )}
        >
          <div className="text-xs text-gray-500 mb-1">
            <Translate id={words.amountDue} />
          </div>
          <div className="text-2xl">
            <MoneyHelper value={invoice.amount} />
          </div>
        </div>
      </div>
      <div className="px-4 space-y-2 mb-6">
        <div className="flex justify-center space-x-4">
          <div className="w-40">
            <div className="text-xs text-gray-500 mb-0.5">
              <Translate id={invoicesKeys.date} />
            </div>
            <div className="text-sm">
              <DateTimeHelper date={invoice.createdAt} />
            </div>
          </div>
          <div className="w-24">
            <div className="text-xs text-gray-500 mb-0.5">
              <Translate id={invoicesKeys.id} />
            </div>
            <div className="text-sm">{invoice.id}</div>
          </div>
        </div>
        <div className="flex justify-center space-x-4">
          {invoice?.generalLedger?.company ? (
            <div className="w-40">
              <div className="text-xs text-gray-500 mb-0.5">
                <Translate id={words.payer} />
              </div>
              <div className="text-sm">
                <TeamLink
                  team={invoice.generalLedger.company}
                  text={invoice.generalLedger.company.name}
                />
              </div>
            </div>
          ) : null}
          <div className="w-24">
            <div className="text-xs text-gray-500 mb-0.5">
              <Translate id={words.status} />
            </div>
            <div className="text-sm">{invoiceStatusTexts[invoice.status]}</div>
          </div>
        </div>
      </div>
      {invoice?.avInvoiceBillingInfo?.paymentMethod ===
        AvBillingInfoPaymentMethods.PAYONEER && (
        <div className="px-4 space-y-2 mb-6">
          <div className="text-xs text-gray-500 mb-0.5">
            <Translate id={invoicesKeys.messages.payoneerWarning} />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default memo<InvoiceCardProps>(InvoiceCard);
