import { useCallback, useState } from 'react';

import { InvoiceNanoID, InvoiceID } from '../../../../../invoicesTypes';
import { AvBillingInfoPaymentMethods } from '../../../../../../avBillingInfos/avBillingInfosTypes';

import {
  FETCH_INVOICE_QUERY,
  FetchInvoiceQueryResponse
} from '../../../../../queries/fetchInvoice.query';

import { useFinInvoice } from '../../../../../hooks/useFinInvoice';

import { PayoneerCheckoutAttemptPaymentDataLinksSelf } from '../../../../../../payoneerCheckoutAttempts/payoneerCheckoutAttemptsTypes';
import { PayoneerAttemptPaymentUrl } from '../../../../../../payoneerAttempts/payoneerAttemptsTypes';

import { useCreatePayoneerAttempt } from '../../../../../../payoneerAttempts/hooks/useCreatePayoneerAttempt';
import { useCreatePayoneerCheckoutAttempt } from '../../../../../../payoneerCheckoutAttempts/hooks/useCreatePayoneerCheckoutAttempt';

import { InvoiceCache } from '../../../../../InvoiceCache';

interface UsePayInvoiceModalButtonOptions {
  invoiceNanoId: InvoiceNanoID;
}

function usePayInvoiceModalButton({
  invoiceNanoId
}: UsePayInvoiceModalButtonOptions) {
  const [refetchingInvoice, setRefetchingInvoice] = useState<boolean>(false);

  const [payoneerCheckoutListUrl, setPayoneerCheckoutListUrl] =
    useState<PayoneerCheckoutAttemptPaymentDataLinksSelf>(null);

  const [payoneerPaymentUrl, setPayoneerPaymentUrl] =
    useState<PayoneerAttemptPaymentUrl>(null);

  const { invoice, invoiceError, invoiceLoading, refetchInvoice } =
    useFinInvoice<FetchInvoiceQueryResponse>({
      invoiceUuid: invoiceNanoId,
      query: FETCH_INVOICE_QUERY,
      cacheKey: InvoiceCache.showCacheKey(),
      options: {
        enabled: false,
        enabledPlaceholder: false
      }
    });

  const {
    createPayoneerCheckoutAttemptLoading,
    createPayoneerCheckoutAttemptErrorMessage,
    createPayoneerCheckoutAttempt
  } = useCreatePayoneerCheckoutAttempt();

  const {
    createPayoneerAttemptLoading,
    createPayoneerAttemptErrorMessage,
    createPayoneerAttempt
  } = useCreatePayoneerAttempt();

  const handleInitializePayoneerCheckoutPayment = useCallback<
    (invoiceId: InvoiceID) => void
  >(
    async (invoiceId) => {
      const response = await createPayoneerCheckoutAttempt({
        payoneerCheckoutAttempt: {
          invoiceId
        }
      });
      setPayoneerCheckoutListUrl(response?.paymentData?.links?.self);
    },
    [createPayoneerCheckoutAttempt]
  );

  const handleInitializePayoneerPayment = useCallback<
    (invoiceId: InvoiceID) => void
  >(
    async (invoiceId) => {
      const response = await createPayoneerAttempt({
        payoneerAttempt: {
          invoiceId
        }
      });
      if (response?.paymentUrl) {
        setPayoneerPaymentUrl(response.paymentUrl);
        return;
      }
    },
    [createPayoneerAttempt]
  );

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    if (payoneerPaymentUrl) {
      window.open(payoneerPaymentUrl);
    }
    return;
  }, [payoneerPaymentUrl]);

  const handleOpen = useCallback<() => void>(async () => {
    try {
      setPayoneerCheckoutListUrl(null);
      setPayoneerPaymentUrl(null);
      setRefetchingInvoice(true);
      const refetchResponse = await refetchInvoice();
      setRefetchingInvoice(false);

      const fetchedInvoice = refetchResponse?.data?.invoice;
      if (!fetchedInvoice) {
        return;
      }

      if (
        fetchedInvoice.avInvoiceBillingInfo?.paymentMethod ===
        AvBillingInfoPaymentMethods.PAYONEER_CHECKOUT
      ) {
        handleInitializePayoneerCheckoutPayment(fetchedInvoice.id);
      }
      if (
        fetchedInvoice.avInvoiceBillingInfo?.paymentMethod ===
        AvBillingInfoPaymentMethods.PAYONEER
      ) {
        handleInitializePayoneerPayment(fetchedInvoice.id);
      }
    } catch (e) {
      setRefetchingInvoice(false);
    }
  }, [
    handleInitializePayoneerCheckoutPayment,
    handleInitializePayoneerPayment,
    refetchInvoice
  ]);

  const isPayoneerCheckoutForm =
    invoice?.avInvoiceBillingInfo?.paymentMethod ===
    AvBillingInfoPaymentMethods.PAYONEER_CHECKOUT;

  return {
    invoice,
    errorMessage:
      createPayoneerCheckoutAttemptErrorMessage ||
      createPayoneerAttemptErrorMessage ||
      invoiceError,
    isLoading:
      createPayoneerCheckoutAttemptLoading ||
      createPayoneerAttemptLoading ||
      invoiceLoading ||
      refetchingInvoice,
    isPayoneerCheckoutForm,
    payoneerCheckoutListUrl,
    handleOpen,
    handleSubmit
  };
}

export default usePayInvoiceModalButton;
