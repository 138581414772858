import React, { Fragment } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { InvoiceNanoID } from '../../../invoicesTypes';

import { useTranslate } from '../../../../../common/hooks/useTranslate';

import { usePayInvoiceModalButton } from './hooks/usePayInvoiceModalButton';

import { InvoicePayoneerCheckoutPaymentContainer } from '../../containers/InvoicePayoneerCheckoutPaymentContainer';
import { InvoiceCard } from '../../cards/InvoiceCard';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

import {
  SimpleModalButton,
  SimpleModalButtonDefaultProps
} from '../../../../../helpers/buttons/SimpleModalButton';

import { invoicesKeys, words } from '../../../../../locales/keys';

interface PayInvoiceModalButtonProps {
  invoiceNanoId: InvoiceNanoID;
}

function PayInvoiceModalButton({
  invoiceNanoId,
  className,
  disabled,
  i18nText,
  icon,
  iconClassName
}: PayInvoiceModalButtonProps & SimpleModalButtonDefaultProps) {
  const { t } = useTranslate();

  const {
    invoice,
    errorMessage,
    isLoading,
    isPayoneerCheckoutForm,
    payoneerCheckoutListUrl,
    handleOpen,
    handleSubmit
  } = usePayInvoiceModalButton({ invoiceNanoId });

  return (
    <SimpleModalButton
      className={className}
      disabled={disabled}
      i18nText={i18nText}
      i18nSubmitText={words.pay}
      childrenClassName="flex-1 overflow-y-auto px-2"
      buttonsContainerClassName="flex p-4 justify-center"
      submitClass="py-2 pl-2 pr-4 space-x-1 rounded-md flex m-auto items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:bg-blue-500 hover:shadow-md w-72 max-w-full justify-center"
      submitIcon={IconsEnum.CASH}
      submitIconClassName="h-6 w-6 p-0.5"
      modalSize="sm"
      title={
        invoice
          ? `${t(invoicesKeys.singular)} #${invoice.id}`
          : t(invoicesKeys.singular)
      }
      icon={icon}
      iconClassName={iconClassName}
      modalIcon={IconsEnum.CASH}
      withoutCancelButton
      withoutButtons={isPayoneerCheckoutForm ? true : false}
      onSubmit={handleSubmit}
      onOpen={handleOpen}
      submitDisabled={isLoading}
      allowPropagation={isPayoneerCheckoutForm}
    >
      <div className="p-4">
        <LoadingSkeleton loaded={!isLoading}>
          {invoice ? (
            <Fragment>
              <InvoiceCard invoice={invoice} />
              {payoneerCheckoutListUrl ? (
                <InvoicePayoneerCheckoutPaymentContainer
                  listUrl={payoneerCheckoutListUrl}
                />
              ) : null}
            </Fragment>
          ) : null}
        </LoadingSkeleton>
        <AlertMessage addClassName="mt-2" message={errorMessage} />
      </div>
    </SimpleModalButton>
  );
}

export default PayInvoiceModalButton;
