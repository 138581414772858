import { gql } from 'graphql-request';
import {
  InvoiceAccountTransactionCount,
  InvoiceAmount,
  InvoiceAvInvoiceBillingInfoAddress,
  InvoiceAvInvoiceBillingInfoBankDetails,
  InvoiceAvInvoiceBillingInfoCity,
  InvoiceAvInvoiceBillingInfoCompanyName,
  InvoiceAvInvoiceBillingInfoContactEmail,
  InvoiceAvInvoiceBillingInfoContactName,
  InvoiceAvInvoiceBillingInfoContactPhone,
  InvoiceAvInvoiceBillingInfoCountry,
  InvoiceAvInvoiceBillingInfoID,
  InvoiceAvInvoiceBillingInfoName,
  InvoiceAvInvoiceBillingInfoNanoID,
  InvoiceAvInvoiceBillingInfoNotes,
  InvoiceAvInvoiceBillingInfoPaymentMethods,
  InvoiceAvInvoiceBillingInfoState,
  InvoiceAvInvoiceBillingInfoZipCode,
  InvoiceBurning,
  InvoiceClientId,
  InvoiceClientStatus,
  InvoiceCreatedAt,
  InvoiceDisabledBindButton,
  InvoiceDisabledCancelButton,
  InvoiceDisabledPayCardButton,
  InvoiceDisabledPayWireButton,
  InvoiceDisabledRefundButton,
  InvoiceDisabledSendButton,
  InvoiceDisabledUnbindButton,
  InvoiceDisabledUncollectibleButton,
  InvoiceDisabledVoidButton,
  InvoiceDueDate,
  InvoiceGeneralLedgerCompanyID,
  InvoiceGeneralLedgerCompanyName,
  InvoiceGeneralLedgerCompanyNanoID,
  InvoiceID,
  InvoiceInvoiceBillingInfoAddress,
  InvoiceInvoiceBillingInfoCity,
  InvoiceInvoiceBillingInfoCompanyName,
  InvoiceInvoiceBillingInfoContactEmail,
  InvoiceInvoiceBillingInfoContactPhone,
  InvoiceInvoiceBillingInfoCountry,
  InvoiceInvoiceBillingInfoFirstName,
  InvoiceInvoiceBillingInfoID,
  InvoiceInvoiceBillingInfoLastName,
  InvoiceInvoiceBillingInfoNanoID,
  InvoiceInvoiceBillingInfoState,
  InvoiceInvoiceBillingInfoZipCode,
  InvoiceInvoiceType,
  InvoiceJournalRecordImportID,
  InvoiceNanoID,
  InvoiceNotes,
  InvoicePaid,
  InvoicePaidAt,
  InvoicePaymentDate,
  InvoicePaymentMethod,
  InvoicePrepayment,
  InvoicePrepaymentAmount,
  InvoiceProjectID,
  InvoiceReceived,
  InvoiceReturnReasons,
  InvoiceShowBindButton,
  InvoiceShowCancelButton,
  InvoiceShowPayCardButton,
  InvoiceShowPayWireButton,
  InvoiceShowRefundButton,
  InvoiceShowSendButton,
  InvoiceShowUnbindButton,
  InvoiceShowUncollectibleButton,
  InvoiceShowVoidButton,
  InvoiceSmartContractID,
  InvoiceSmartContractProjectOwnerFullName,
  InvoiceSmartContractProjectOwnerNanoID,
  InvoiceSmartContractTaskCreatedAt,
  InvoiceSmartContractTaskName,
  InvoiceSmartContractTaskNanoID,
  InvoiceSmartContractTaskOwnerFullName,
  InvoiceSmartContractTaskOwnerNanoID,
  InvoiceSmartContractTaskProjectCreatedAt,
  InvoiceSmartContractTaskProjectName,
  InvoiceSmartContractTaskProjectNanoID,
  InvoiceStatus,
  InvoiceTerms,
  InvoiceTotal,
  InvoiceTransactionFeeExpense,
  InvoiceUUID
} from '../invoicesTypes';
import {
  TeamAccountReceivables,
  TeamEmail,
  TeamImageFile,
  TeamUUID
} from '../../teams/teamsTypes';
import { ItemTypeName } from '../../itemTypes/itemTypesTypes';
import { ItemCategoryName } from '../../itemCategories/itemCategoriesTypes';
import { ItemPrice, ItemSmartContractID } from '../../items/itemsTypes';
import { MayBe } from '../../../types';

export interface FetchInvoiceQueryResponse {
  id: InvoiceID;
  nanoId: InvoiceNanoID;
  uuid: InvoiceUUID;
  status: InvoiceStatus;
  dueDate: InvoiceDueDate;
  amount: InvoiceAmount;
  accountTransactionCount: InvoiceAccountTransactionCount;
  createdAt: InvoiceCreatedAt;
  invoiceType: InvoiceInvoiceType;
  invoiceTotal: InvoiceTotal;
  paymentMethod: InvoicePaymentMethod;
  showPayWireButton: InvoiceShowPayWireButton;
  disabledPayWireButton: InvoiceDisabledPayWireButton;
  showUncollectibleButton: InvoiceShowUncollectibleButton;
  disabledUncollectibleButton: InvoiceDisabledUncollectibleButton;
  showBindButton: InvoiceShowBindButton;
  disabledBindButton: InvoiceDisabledBindButton;
  showUnbindButton: InvoiceShowUnbindButton;
  disabledUnbindButton: InvoiceDisabledUnbindButton;
  showPayCardButton: InvoiceShowPayCardButton;
  disabledPayCardButton: InvoiceDisabledPayCardButton;
  showSendButton: InvoiceShowSendButton;
  disabledSendButton: InvoiceDisabledSendButton;
  showRefundButton: InvoiceShowRefundButton;
  disabledRefundButton: InvoiceDisabledRefundButton;
  showCancelButton: InvoiceShowCancelButton;
  disabledCancelButton: InvoiceDisabledCancelButton;
  showVoidButton: InvoiceShowVoidButton;
  disabledVoidButton: InvoiceDisabledVoidButton;
  burning: InvoiceBurning;
  paid: InvoicePaid;
  paidAt: InvoicePaidAt;
  received: InvoiceReceived;
  transactionFeeExpense: InvoiceTransactionFeeExpense;
  clientId: InvoiceClientId;
  clientStatus: InvoiceClientStatus;
  paymentDate: InvoicePaymentDate;
  prepayment: InvoicePrepayment;
  returnReason: InvoiceReturnReasons;
  terms: InvoiceTerms;
  projectId: InvoiceProjectID;
  notes?: InvoiceNotes;
  prepaymentAmount: InvoicePrepaymentAmount;
  journalRecordImportId: InvoiceJournalRecordImportID;
  generalLedger: {
    company: {
      id: InvoiceGeneralLedgerCompanyID;
      nanoId: InvoiceGeneralLedgerCompanyNanoID;
      name: InvoiceGeneralLedgerCompanyName;
      accountReceivables: TeamAccountReceivables;
      uuid: TeamUUID;
      email: TeamEmail;
      image: {
        file: TeamImageFile;
      } | null;
    };
  };
  invoiceBillingInfo: {
    id: InvoiceInvoiceBillingInfoID;
    nanoId: InvoiceInvoiceBillingInfoNanoID;
    companyName: InvoiceInvoiceBillingInfoCompanyName;
    firstName: InvoiceInvoiceBillingInfoFirstName;
    lastName: InvoiceInvoiceBillingInfoLastName;
    contactPhone: InvoiceInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceInvoiceBillingInfoContactEmail;
    country: InvoiceInvoiceBillingInfoCountry;
    state: InvoiceInvoiceBillingInfoState;
    city: InvoiceInvoiceBillingInfoCity;
    address: InvoiceInvoiceBillingInfoAddress;
    zipCode: InvoiceInvoiceBillingInfoZipCode;
  };
  avInvoiceBillingInfo: {
    id: InvoiceAvInvoiceBillingInfoID;
    nanoId: InvoiceAvInvoiceBillingInfoNanoID;
    name: InvoiceAvInvoiceBillingInfoName;
    companyName: InvoiceAvInvoiceBillingInfoCompanyName;
    contactName: InvoiceAvInvoiceBillingInfoContactName;
    contactPhone: InvoiceAvInvoiceBillingInfoContactPhone;
    contactEmail: InvoiceAvInvoiceBillingInfoContactEmail;
    country: InvoiceAvInvoiceBillingInfoCountry;
    state: InvoiceAvInvoiceBillingInfoState;
    city: InvoiceAvInvoiceBillingInfoCity;
    address: InvoiceAvInvoiceBillingInfoAddress;
    zipCode: InvoiceAvInvoiceBillingInfoZipCode;
    paymentMethod: InvoiceAvInvoiceBillingInfoPaymentMethods;
    notes?: InvoiceAvInvoiceBillingInfoNotes;
    bankDetails: InvoiceAvInvoiceBillingInfoBankDetails;
  };
  secondaryInvoice: MayBe<{
    id: InvoiceID;
    nanoId: InvoiceNanoID;
    amount: InvoiceAmount;
    showPayWireButton: InvoiceShowPayWireButton;
    disabledPayWireButton: InvoiceDisabledPayWireButton;
    showPayCardButton: InvoiceShowPayCardButton;
    disabledPayCardButton: InvoiceDisabledPayCardButton;
    clientStatus: InvoiceClientStatus;
    status: InvoiceStatus;
    paidAt: InvoicePaidAt;
    avInvoiceBillingInfo: {
      paymentMethod: InvoiceAvInvoiceBillingInfoPaymentMethods;
    };
  }>;
  childInvoices: {
    id: InvoiceID;
    item: {
      itemTypeName: ItemTypeName;
      itemCategoryName: ItemCategoryName;
      price: ItemPrice;
      smartContractId: ItemSmartContractID;
    };
    smartContract: {
      id: InvoiceSmartContractID;
      task: {
        createdAt: InvoiceSmartContractTaskCreatedAt;
        nanoId: InvoiceSmartContractTaskNanoID;
        name: InvoiceSmartContractTaskName;
        owner: {
          nanoId: InvoiceSmartContractTaskOwnerNanoID;
          fullName: InvoiceSmartContractTaskOwnerFullName;
        };
        project: {
          createdAt: InvoiceSmartContractTaskProjectCreatedAt;
          nanoId: InvoiceSmartContractTaskProjectNanoID;
          name: InvoiceSmartContractTaskProjectName;
          owner: {
            nanoId: InvoiceSmartContractProjectOwnerNanoID;
            fullName: InvoiceSmartContractProjectOwnerFullName;
          };
        };
      };
    };
  }[];
}

export const FETCH_INVOICE_QUERY = gql`
  query Invoice($uuid: ID!) {
    invoice(uuid: $uuid) {
      amount
      accountTransactionCount
      avInvoiceBillingInfo {
        address
        bankDetails
        city
        companyName
        contactName
        contactEmail
        contactPhone
        country
        id
        name
        nanoId
        notes
        paymentMethod
        state
        zipCode
      }
      burning
      childInvoices {
        id
        item {
          itemCategoryName
          itemTypeName
          price
          smartContractId
        }
        smartContract {
          id
          task {
            createdAt
            nanoId
            name
            owner {
              nanoId
              fullName
            }
            project {
              createdAt
              nanoId
              name
              owner {
                nanoId
                fullName
              }
            }
          }
        }
      }
      clientId
      clientStatus
      createdAt
      deferredPaymentDays
      description
      disabledPayWireButton
      disabledUncollectibleButton
      disabledBindButton
      disabledUnbindButton
      disabledPayCardButton
      disabledSendButton
      disabledRefundButton
      disabledCancelButton
      disabledVoidButton
      dueDate
      generalLedger {
        company {
          id
          nanoId
          name
          accountReceivables
          uuid
          email
          image {
            file
          }
        }
      }
      id
      invoiceBillingInfo {
        address
        city
        companyName
        contactEmail
        contactPhone
        country
        firstName
        id
        lastName
        nanoId
        state
        zipCode
      }
      invoiceTotal
      invoiceType
      nanoId
      notes
      paid
      paidAt
      paymentDate
      paymentMethod
      prepayment
      prepaymentAmount
      projectId
      received
      returnReason
      secondaryInvoice {
        amount
        avInvoiceBillingInfo {
          paymentMethod
        }
        clientStatus
        disabledPayCardButton
        disabledPayWireButton
        id
        invoiceType
        nanoId
        paid
        paidAt
        showPayCardButton
        showPayWireButton
        status
      }
      showPayWireButton
      showUncollectibleButton
      showBindButton
      showUnbindButton
      showPayCardButton
      showSendButton
      showRefundButton
      showCancelButton
      showVoidButton
      status
      terms
      transactionFeeExpense
      journalRecordImportId
      uuid
    }
  }
`;
